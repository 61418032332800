.std-video-dialog {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
}

.std-video-dialog .std-video-dialog--container {
  margin: auto;
  width: 90%;
  height: 90%;
  display: flex;
  position: relative;
  background-color: black;
}

.std-video-dialog--container video,
.std-video-dialog--container iframe {
  width: 100%;
}

.std-video-dialog--close-bttn {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
}

.std-video-dialog--close-bttn svg {
  width: 3rem;
  height: 3rem;
  color: #666;
}

.std-video-dialog--close-bttn:hover svg {
  color: #36c;
}

.std-video-dialog--close-bttn:active svg {
  color: #14a;
}
