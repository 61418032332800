.std-bttn-close {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.std-bttn-close svg {
  width: 26px;
  height: 26px;
}

#root .std-bttn-close svg {
  pointer-events: all;
}

.std-bttn-close svg:hover {
  color: #36c;
}

.std-bttn-close svg:active {
  color: #14a;
}

@media only screen and (orientation: portrait) {
  .std-bttn-close {
    top: 2.5rem;
  }
}
