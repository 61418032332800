.v3d-annotation.std-hotspot {
  background-color: #36c;
  line-height: 1rem;
  padding: 0.3rem;

  /* SVG width and height should be the same as height! */
  min-width: 1rem;
  min-height: 1rem;
  border-radius: 2rem;

  /* Required to place first and second perfectly */
  display: flex;

  height: unset;
}

.v3d-annotation.std-hotspot > * {
  pointer-events: none;
}

.v3d-annotation.std-hotspot:hover {
  background-color: #14a;
}

.v3d-annotation.std-hotspot .first {
  min-width: 1rem;
  min-height: 1rem; /* required for cases where hotspots are turned off and on again */
  margin: 0;
}

/*.v3d-annotation.std-hotspot.open .first,
.v3d-annotation.std-hotspot:hover .first {
  margin: 0 0.3rem;
}*/

.v3d-annotation.std-hotspot .second {
  display: none;
}

.v3d-annotation.std-hotspot .first,
.v3d-annotation.std-hotspot .second {
  font-size: small;
}

.v3d-annotation.std-hotspot.open .second,
.v3d-annotation.std-hotspot:hover .second {
  /*padding-right: 0.4rem;*/
  display: block;
}

.v3d-annotation.std-hotspot .eye,
.v3d-annotation.std-hotspot:hover .eye {
  /* Inside URLs, #-signs must be escaped as %23 */
  background: url('data:image/svg+xml;charset=UTF-8,<svg stroke="%23ffffff" fill="%23ffffff" stroke-width="0" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"><path d="M396 512a112 112 0 1 0 224 0 112 112 0 1 0-224 0zm546.2-25.8C847.4 286.5 704.1 186 512 186c-192.2 0-335.4 100.5-430.2 300.3a60.3 60.3 0 0 0 0 51.5C176.6 737.5 319.9 838 512 838c192.2 0 335.4-100.5 430.2-300.3 7.7-16.2 7.7-35 0-51.5zM508 688c-97.2 0-176-78.8-176-176s78.8-176 176-176 176 78.8 176 176-78.8 176-176 176z"></path></svg>');
}

.v3d-annotation.std-hotspot .scissor,
.v3d-annotation.std-hotspot:hover .scissor {
  transform: scaleX(-1);
  background: url('data:image/svg+xml;charset=UTF-8,<svg stroke="%23ffffff" fill="%23ffffff" stroke-width="0" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"><path d="M567.1 512l318.5-319.3c5-5 1.5-13.7-5.6-13.7h-90.5c-2.1 0-4.2.8-5.6 2.3l-273.3 274-90.2-90.5c12.5-22.1 19.7-47.6 19.7-74.8 0-83.9-68.1-152-152-152s-152 68.1-152 152 68.1 152 152 152c27.7 0 53.6-7.4 75.9-20.3l90 90.3-90.1 90.3A151.04 151.04 0 0 0 288 582c-83.9 0-152 68.1-152 152s68.1 152 152 152 152-68.1 152-152c0-27.2-7.2-52.7-19.7-74.8l90.2-90.5 273.3 274c1.5 1.5 3.5 2.3 5.6 2.3H880c7.1 0 10.7-8.6 5.6-13.7L567.1 512zM288 370c-44.1 0-80-35.9-80-80s35.9-80 80-80 80 35.9 80 80-35.9 80-80 80zm0 444c-44.1 0-80-35.9-80-80s35.9-80 80-80 80 35.9 80 80-35.9 80-80 80z"></path></svg>');
}

.v3d-annotation.std-hotspot .power,
.v3d-annotation.std-hotspot:hover .power {
  background: url('data:image/svg+xml;charset=UTF-8,<svg stroke="%23ffffff" fill="%23ffffff" stroke-width="0" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M279.1 48h-46.2v231.1h46.2V48zm111.6 50.2L357.9 131c36.5 29.4 59.9 74.4 59.9 125 0 89.4-72.3 161.8-161.8 161.8S94.2 345.4 94.2 256c0-50.6 23.3-95.7 59.6-125.3l-32.6-32.6C76.4 136.3 48 192.7 48 256c0 114.9 93.1 208 208 208s208-93.1 208-208c0-63.3-28.4-119.7-73.3-157.8z"></path></svg>');
}

.v3d-annotation.std-hotspot .info,
.v3d-annotation.std-hotspot:hover .info {
  background: url('data:image/svg+xml;charset=UTF-8,<svg stroke="%23ffffff" fill="%23ffffff" stroke-width="0" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M232 235h48v137h-48zM232 140h48v48h-48z"></path></svg>');
}

.v3d-annotation.std-hotspot .plus,
.v3d-annotation.std-hotspot:hover .plus {
  background: url('data:image/svg+xml;charset=UTF-8,<svg stroke="%23ffffff" fill="%23ffffff" stroke-width="0" t="1551322312294" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><defs></defs><path d="M474 152m8 0l60 0q8 0 8 8l0 704q0 8-8 8l-60 0q-8 0-8-8l0-704q0-8 8-8Z"></path><path d="M168 474m8 0l672 0q8 0 8 8l0 60q0 8-8 8l-672 0q-8 0-8-8l0-60q0-8 8-8Z"></path></svg>');
}

.v3d-annotation.std-hotspot .minus,
.v3d-annotation.std-hotspot:hover .minus {
  background: url('data:image/svg+xml;charset=UTF-8,<svg stroke="%23ffffff" fill="%23ffffff" stroke-width="0" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"><path d="M872 474H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h720c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8z"></path></svg>');
}

.v3d-annotation.std-hotspot .saw,
.v3d-annotation.std-hotspot:hover .saw {
  background: url('data:image/svg+xml;charset=UTF-8,<svg stroke="%23ffffff" fill="%23ffffff" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M256 16v72H88l48 48L16 256h72v168l48-48 120 120v-72h168l-48-48 120-120h-72V88l-48 48L256 16zm0 120c66.274 0 120 53.726 120 120s-53.726 120-120 120-120-53.726-120-120 53.726-120 120-120zm1.406 72.03A48 48 0 0 0 208 256a48 48 0 0 0 96 0 48 48 0 0 0-46.594-47.97z"></path></svg>');
}

.v3d-annotation.std-hotspot.std-hotspot--larger {
  min-width: 1.5rem;
  min-height: 1.5rem;
}

.v3d-annotation.std-hotspot.std-hotspot--larger .first {
  margin: auto;
  transform: scale(1.5);
}
