.std-side-menu > div {
  overflow-y: auto;
  overflow-x: hidden;
}

.std-side-menu {
  background-color: #657186;
  color: white;
  overflow: auto;
  flex-shrink: 0;
}

.std-side-menu.openSlowly {
  transition: all 1s;
  transition-timing-function: ease-in-out;
}

.std-side-menu.openImmediately {
  transition: unset;
  transition-timing-function: unset;
}

.std-side-menu.closeImmediately {
  transition: unset;
  transition-timing-function: unset;
}

.std-side-menu.closeSlowly {
  transition: all 1s;
  transition-timing-function: ease-in-out;
}

@media only screen and (orientation: landscape) {
  .std-side-menu {
    width: 0;
    height: 100%;
  }

  .std-side-menu.openSlowly,
  .std-side-menu.openImmediately {
    width: 18rem;
  }

  .std-side-menu.closeImmediately,
  .std-side-menu.closeSlowly {
    width: 0;
  }
}

@media only screen and (pointer: fine) and (hover: hover) and (orientation: landscape) {
  #menu-info {
    background-color: unset;
    /*position: absolute;*/
    z-index: 2;
    left: 4rem;
    /* Dark backgrounds */
    color: white;
    text-shadow: 2px 2px black;
    /* Light backgrounds */
    /* color: black;
    text-shadow: 2px 2px cornsilk; */
    /*pointer-events: none;*/
  }
}

@media only screen and (orientation: portrait) {
  .std-side-menu {
    width: 100%;
    height: 0;
  }

  #menu-info.openSlowly,
  #menu-info.openImmediately {
    height: 45%;
  }

  #menu-info.closeImmediately,
  #menu-info.closeSlowly {
    height: 0;
  }

  #menu-settings.openSlowly,
  #menu-help.openSlowly,
  #menu-lang.openSlowly,
  #menu-settings.openImmediately,
  #menu-help.openImmediately,
  #menu-lang.openImmediately {
    height: calc(100% - 6.4rem);
  }

  #menu-settings.closeImmediately,
  #menu-help.closeImmediately,
  #menu-lang.closeImmediately,
  #menu-settings.closeSlowly,
  #menu-help.closeSlowly,
  #menu-lang.closeSlowly {
    height: 0;
  }
}
