.std-content-page .std-copyright {
  text-align: center;
  padding: 0.5rem 0 1rem 0;
  font-size: 0.825rem;
  position: relative;
  height: 100%;
  width: 100%;
  margin: 3rem 0 0; /* additional distance in portrait mode to avoid overlaps */
}

.std-content-page .std-copyright a {
  border-top: 1px solid lightgrey;
  text-decoration: none;
  color: inherit;
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  right: 1rem;
}

.std-content-page .std-copyright a:link,
.std-content-page .std-copyright a:visited {
  color: white;
}

.std-content-page .std-copyright a:hover {
  color: lightgray;
}

.std-content-page .std-copyright a:active {
  color: white;
}
