/**
  Table of Contents
  - it must conceal the content menu on small screens in portrait mode
  - it must take up the same height as the content menu on small screens in portrait mode
  - it must stick above the govie panel on larger devices
*/

.std-toc {
  background-color: #657186;
  border-bottom: 1px solid white;
  color: white;

  /* Hide text overflow, but present scrollbar if necessary */
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;

  /* Open/close animation */
  transition: height 1s;
  transition-timing-function: ease-in-out;
  max-height: calc(100vh - 6.4rem); /* 100% - govie menu */

  /* Comment does exist just for grouping */
  position: absolute;
  z-index: 2;
  height: 45%;
  order: 3;
  bottom: 0;

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.std-toc.isClosed {
  /* Open/close animation */
  height: 0;
  border-bottom: 0 solid white;
}

.std-toc--head {
  background-color: #364662;
  position: sticky;
  top: 0;
  display: inline-flex;
  justify-content: center;
  margin: 0;
  width: 100%;
  height: 2rem;
  align-items: center;
  border-bottom: 1px solid white;
}

.std-toc--head-centered {
  /* Line clamping is not supported by every browser yet */
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.std-toc--content {
  margin: auto;
  hyphens: auto;
  text-align: justify;

  /* Display vertical scrollbar
  - 2rem are removed to avoid some issue scroll until top or bottom.
  - I believe, that the height of the std-toc--head does have an influence. 
  */
  overflow-y: scroll;
  height: calc(100% - 2rem);
}

.std-toc--content-item {
  display: inline-flex;
  align-content: space-between;
  margin: 0.5rem 0;
  min-height: 2rem;
  width: 100%;
}

.std-toc--content-item-column {
  display: inline-flex;
  align-content: space-between;
  justify-content: center;
  margin: 0;
}

.std-toc--content-item-column-index {
  margin: auto;
  width: 4rem;
  overflow: hidden;
  text-align: center;
}

.std-toc--content-item-column-separator {
  border: 1px solid;
  width: 0;
}

.std-toc--content-item-column-text {
  /* Line clamping is not supported by every browser yet */
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  margin: auto 2rem auto 0.5rem;
  text-align: left;
}

.std-toc--content-item:hover {
  color: darkorange;
}

.std-toc--content-item:active {
  color: gray;
}

.std-toc--content-item.isActive {
  color: orange;
}

.std-toc--content-item.isActive:hover {
  color: darkorange;
}

.std-toc--content-item.isActive:active {
  color: gray;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.std-toc::-webkit-scrollbar {
  display: none;
}

@media only screen and (orientation: landscape) {
  .std-toc {
    position: absolute;
    bottom: 7.4em;
    left: 0;
    right: 0;
    width: 20rem;
    margin: auto;
    max-width: 90%;
    border-radius: 0.5rem 0.5rem 0 0;
  }

  .std-toc.hiddenInLandscapeMode {
    display: none;
  }
}

@media only screen and (orientation: portrait) {
  .std-toc {
    bottom: 6.4rem;

    /* (100% - height of #_3d-wrapper) = 55%; height of std-playbar = 6.4em  */
    height: calc(55% - 6.4rem);
  }
}

@media only screen and (pointer: coarse) and (hover: none) and (orientation: landscape) {
  .std-toc {
    bottom: 0;
    max-width: unset;
    width: 100%;
  }
}

@media only screen and (pointer: coarse) and (hover: none) and (orientation: landscape) and (min-width: 75rem) {
  .std-toc--content {
    display: flex;

    /* width: 100%; */
    flex-flow: wrap;
    justify-content: flex-start;
    align-items: baseline;
    align-content: flex-start;
  }

  .std-toc--content-item {
    display: inline-flex;

    /* align-content: space-between; */
    width: 50%;
  }
}
