#splash-screen {
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  background: white;
  z-index: 2;
  top: 0;
}

#splash-screen--center {
  margin: auto;
  width: 50%;
}

#splash-screen--image {
  display: flex;
  width: 70%;
  height: auto;
  margin: auto;
}

#splash-screen--progress-bar {
  background-color: #364662;
  height: 0.5rem;
  width: 100%;
  border-radius: 1rem;
  margin: 1rem 0;
}

#splash-screen--progress-bar-value {
  width: 0%;
  height: 100%;
  background-color: orange;
  border-radius: 1rem;
}

#splash-screen--progress-bar-text {
  text-align: center;
}
