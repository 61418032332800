.user-info {
  position: absolute;
  top: 8rem;
  right: 0.5rem;
  /*display: flex;
  justify-content: flex-start;*/
  width: 2rem;
  height: 2rem;
}

/* Derzeit nicht in Gebrauch
.user-info--progress-bg {
  width: 5rem;
  height: 0.25rem;
  margin: auto;
  background-color: lightgray;
}

.user-info--progress-value {
  background-color: red;
  width: 50%;
  height: inherit;
}*/

.user-info--icon {
  margin: auto;
}

.user-info--icon svg {
  margin: auto;
  display: block;
  fill: red;
  width: 100%;
  height: 100%;
}
