.debug--animation {
  position: fixed;
  top: 1rem;
  right: 1rem;
  background-color: lightgoldenrodyellow;
  padding: 0 1rem 1rem;
}

.debug--animation-field.isInactive {
  pointer-events: none;
  color: lightgray;
}

.debug--animation-slider {
  width: 12rem;
  display: inline-block;
  height: 1rem;
}

.debug--animation-thumb {
  height: 1.5rem;
  line-height: 25px;
  width: 25px;
  text-align: center;
  background-color: white;
  color: black;
  border-radius: 50%;
  cursor: grab;
  font-size: small;
  border: 1px solid darkgray;
  margin: 0;
  z-index: unset !important;
}

.debug--animation-track {
  background-color: white;
  height: 0.5rem;
  border-radius: 2rem;
  top: 0.5rem;
  margin: 0;
}

.debug--animation-track-0 {
  background-color: #2f3750;
}

.debug--animation-track-1 {
  background-color: lightgray;
}

.debug--animation-minBttn,
.debug--animation-maxBttn {
  width: 1rem;
  height: 1rem;
  border: 1px solid black;
  display: inline-block;
}

.debug--animation-input {
  width: 4rem;
  border-color: lightgray;
}

.debug--animation-field.isInactive .debug--animation-input {
  color: lightgray;
}
