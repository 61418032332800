.std-content-page {
  overflow-y: visible;

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  pointer-events: all;
}

.std-content-page > * {
  margin: 1rem;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.std-content-page::-webkit-scrollbar {
  display: none;
}

.std-content-page iframe {
  width: 14rem;
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: stretch;
}

/* In case markdown files contain raw html tags */
.std-content-page .img-container,
.std-content-page code {
  display: block;
  margin: 1rem;
  width: 14rem;
  width: -moz-available;
  width: -webkit-fill-available;
  width: stretch;
}

.std-content-page .img-container {
  position: relative;
}

.std-content-page img {
  width: 100%;
  margin: 0;
}

.std-content-page .img-clickable {
  cursor: pointer;
}

.std-content-page .fullscreen-icon {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  width: 2rem;
  height: 2rem;
  pointer-events: none;
}

.std-content-page code {
  word-break: break-all;
}

.std-content-page blockquote {
  font-style: italic;
}

/* In case markdown files contain their own grammar */
.std-content-page p img,
.std-content-page p code {
  display: block;
  width: 100%;
  margin: 1rem auto;
}

.std-content-page .dev-info {
  border-top: 1px solid orange;
  color: orange;
  padding: 1rem 0;
  font-weight: 700;
  font-size: larger;
}

.std-content-page .dev-info--markdown {
  color: chartreuse;
}

.std-content-page .dev-info--html {
  color: chartreuse;
}

.std-content-page #dev-sample--id {
  color: yellow;
}

.std-content-page .dev-sample--class {
  color: yellowgreen;
}

.std-content-page--error {
  color: red;
}

.std-content-page video {
  width: 100%;
  margin: 0;
}

.std-content-page table {
  margin: auto;
  /*font-family: arial, sans-serif;*/
  font-family: 'FunctionPro_Bookregular', sans-serif;
  border-collapse: collapse;
  table-layout: fixed;
  width: fit-content;
}

.std-content-page table td,
.std-content-page table th {
  border: 1px solid #ddd;
  text-align: left;
  padding: 8px;
}

.std-content-page table tr:nth-child(even) {
  background-color: #a3a0c5;
}

.std-content-page .md-table table {
  font-size: smaller;
  display: flex;
}

.std-content-page .md-button {
  height: 4rem;
  background-color: #364662;
  display: flex;
}

.std-content-page .md-button span {
  margin: auto;

  /* Make sure, that click event is forwarded to surrounding element */
  pointer-events: none;
}

.std-content-page button.md-button {
  width: -webkit-fill-available;
  color: white;
}

.std-content-page .md-button:hover {
  background-color: #2f3750;
}

.std-content-page .md-button:active {
  background-color: #657186;
}

.std-content-page .md-link {
  display: flex;
}

.std-content-page .md-link div {
  display: flex;
}

.std-content-page .md-link div svg {
  font-size: 2rem;
  margin: auto 1rem;
}

.std-content-page .md-link a {
  font-size: 1.2rem;
  margin: auto 0;
}

.std-content-page a {
  width: 100%;
}

.std-content-page a:link,
.std-content-page a:visited {
  color: pink;
}

.std-content-page a:hover {
  color: lightgray;
}

.std-content-page a:active {
  color: palevioletred;
}

/* unvisited and visited */
.std-content-page .md-link a:link,
.std-content-page .md-link a:visited {
  color: white;
}

.std-content-page .md-link a:hover {
  color: orange;
}

.std-content-page .md-link a:active {
  color: darkorange;
}

.logo-container {
  height: 4rem;
  margin: 1rem;
  width: unset;
  padding: 0.5rem;
  display: flex;
}

#beinlich-logo,
#dst-logo,
#hbe-logo,
#vse-logo,
#oleotec-logo {
  margin: auto auto auto 0;
}

#beinlich-logo {
  width: 100%;
}

#dst-logo {
  width: 80%;
}

#hbe-logo {
  width: 100%;
}

#vse-logo {
  width: 70%;
}

#oleotec-logo {
  width: 80%;
}
