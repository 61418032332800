.std-sidebar {
  height: 100%;
  display: inline-flex;
  z-index: 1;
}

.std-sidebar--actions {
  width: 4rem;
  transition: width 1s;
  transition-timing-function: ease-in-out;

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.std-sidebar--actions::-webkit-scrollbar {
  display: none;
}

.std-sidebar--actions-separator {
  background-color: white;
  height: 1px;
}

.std-sidebar--actions-bttn {
  margin: auto;
  max-width: 4rem;
  height: 4rem;
  transition: width 1s;
  transition-timing-function: ease-in-out;
}

.std-sidebar.closed .std-sidebar--actions-bttn {
  width: 0;
}

.std-sidebar--actions-bttn-icon {
  margin: auto;

  /* To center his child, the position of its parent must be relative */
  display: flex;
  align-items: center;
  height: 100%;
  color: white;
}

.std-sidebar--actions-bttn-icon svg {
  /* Required for centering */
  margin: 0 auto;
  display: block;
  font-size: 1.75rem;
}

.std-sidebar--actions-bttn-icon span {
  margin: auto;
  font-weight: 700;
}

.std-sidebar--actions-bttn-icon.isActive {
  color: orange;
}

.std-sidebar--actions-bttn-icon:hover {
  color: darkorange;
  cursor: pointer;
}

.std-sidebar--actions-bttn-icon:active {
  color: gray;
}

.std-sidebar--actions-bttn-icon.isInactive {
  color: gray;
  pointer-events: none;
}

.std-sidebar--actions-bttn-icon.isInactive:hover {
  cursor: default;
}

.std-sidebar--actions-bttn-icon.isActive:hover {
  color: darkorange;
}

.std-sidebar--actions-bttn-icon.isActive:active {
  color: gray;
}

.std-sidebar--actions-bttn-icon.nested {
  position: relative;
}

.std-sidebar--actions-bttn-icon.nested svg {
  /*opacity: 0.3;*/
  transform: scale(1.3);
}

.std-sidebar--actions-bttn-icon.nested div {
  position: absolute;
  width: 100%;
  text-align: center;
  color: white;
  font-size: larger;
}

.std-sidebar--content {
  display: block;
  background-color: #657186;
  height: 100%;

  /* Use 100vw instead of 100% to avoid small visual errors when sidebar is being closed */
  width: 100%;
  font-size: 1rem;
  transition: width 1s;
  transition-timing-function: ease-in-out;
  color: white;

  /* Its children must have overflow-y: visible; or similar */
  overflow-y: auto;

  /* And to avoid a visible horizontal scrollbar, turn it off */
  overflow-x: hidden;
}

@media only screen and (orientation: landscape) {
  .std-sidebar {
    border-right: 2px solid #666;
  }

  .std-sidebar--top {
    max-height: calc(100% - 4rem);
  }

  .std-sidebar.isClosed {
    width: 4rem;
  }

  .std-sidebar--actions {
    transition: unset;
    height: calc(100% - 4rem);
    min-width: 4rem;
    display: initial;
  }

  .std-sidebar--content {
    width: 16rem;
  }

  .std-sidebar.isClosed .std-sidebar--actions {
    width: 4rem;
  }

  .std-sidebar.isClosed .std-sidebar--actions-bttn {
    width: unset;
  }

  .std-sidebar.isClosed .std-sidebar--content {
    width: 0;
  }

  .std-sidebar--bottom {
    bottom: 0;
    position: fixed;
    width: 4rem;
  }

  .std-sidebar--default-actions {
    background-color: #364662;
  }
}

@media only screen and (orientation: portrait) {
  .std-sidebar {
    width: 4rem;
    display: unset;
    align-content: unset;
    order: 2;
    transition: height 1s;
    position: absolute;
    top: 0;
    right: 0;
    height: 45%;
  }

  .std-sidebar.isClosed {
    height: 0;
  }

  .std-sidebar--content {
    transition: height 1s;
  }

  .std-sidebar.isClosed .std-sidebar--content {
    width: 100%;
  }

  .std-sidebar--actions {
    top: 2.5rem;
    right: 0;
    width: 4rem;
    background-color: #657186;
    border-left: 2px solid #666;
    border-bottom: 2px solid #666;
    /*max-height: calc(100% - 10.4rem);*/
    display: block;
    overflow: scroll;
  }

  .std-sidebar--actions-bttn {
    height: 4rem;
  }

  .std-sidebar .std-sidebar--actions.isClosed {
    width: 0;
  }

  .std-sidebar--default-actions {
    display: none;
  }

  .std-sidebar--top {
    height: 100%;
  }
}

.std-sidebar--top {
  overflow-x: hidden;
  overflow-y: auto;
  width: 4rem;
  background-color: #2f3750;
}
