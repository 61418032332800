/**
  Bookmark
  - it must open/close "Table of Contents" menu
  - it must highlight everything on mouseover
  - it must center text horizontally and vertically
  - it must limit the textarea (max. 2 lines)
*/

.std-bookmark {
  width: 100%;
  margin: auto;

  /* To center this child, the position of its parent must be relative */
  top: 50%;
  left: 50%;

  /* Allow children to multi-lines via display: inline-flex; */
  display: flex;
  justify-content: center;
  position: absolute;
  transform: translate(-50%, -50%);
  align-content: space-between;
}

.std-bookmark.isInactive {
  pointer-events: none;
  color: gray;
}

.std-bookmark--column {
  display: inline-flex;
  margin: 0;
  height: 3rem;
}

.std-bookmark--column-expanded {
  width: 100%;
}

.std-bookmark--column-index {
  margin: auto;
  width: 4rem;
  overflow: hidden;
  text-align: center;
}

.std-bookmark--column-separator {
  border: 1px solid;
  width: 0;
}

.std-bookmark--column-text {
  /* Line clamping is not supported by every browser yet */
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin: auto 0.5rem;
  width: 100%;
}

.std-bookmark--column-icon {
  margin: auto;
  width: 4rem;
  min-width: 3rem;
  overflow: hidden;
  text-align: center;
}

.std-bookmark--column-icon svg {
  font-size: 1.75rem;
}

.std-bookmark.isActive {
  color: orange;
}

.std-bookmark:hover {
  color: darkorange;
}

.std-bookmark:active {
  color: gray;
}
