.std-portrait-header {
  background-color: #364662;
  height: 2.5rem;
  color: white;
  display: flex;
  align-content: space-between;
  justify-content: center;
}

.std-portrait-header--left,
.std-portrait-header--right {
  display: flex;
  margin: auto 0;
}

.std-portrait-header--left {
  min-width: 2rem;
}

.std-portrait-header--left-bttn,
.std-portrait-header--right-bttn {
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-content: space-between;
  justify-content: center;
}

.std-portrait-header--left-bttn span,
.std-portrait-header--right-bttn span {
  margin: auto;
  line-height: 1.2;
}

.std-portrait-header--left-bttn svg,
.std-portrait-header--right-bttn svg {
  margin: auto;
  font-size: 1.4rem;
}

.std-portrait-header--center {
  display: flex;
  align-items: center;
  width: 100%;
  margin: auto 1rem;
}

.std-portrait-header--center-title {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.std-portrait-header--left-bttn.isActive span,
.std-portrait-header--right-bttn.isActive span,
.std-portrait-header--left-bttn.isActive svg,
.std-portrait-header--right-bttn.isActive svg {
  color: orange;
}

.std-portrait-header--left-bttn:hover span,
.std-portrait-header--right-bttn:hover span,
.std-portrait-header--left-bttn:hover svg,
.std-portrait-header--right-bttn:hover svg {
  color: darkorange;
}

.std-portrait-header--left-bttn:active span,
.std-portrait-header--right-bttn:active span,
.std-portrait-header--left-bttn:active svg,
.std-portrait-header--right-bttn:active svg {
  color: gray;
}

.std-portrait-header--left-bttn.isActive:hover span,
.std-portrait-header--right-bttn.isActive:hover span,
.std-portrait-header--left-bttn.isActive:hover svg,
.std-portrait-header--right-bttn.isActive:hover svg {
  color: darkorange;
}

.std-portrait-header--left-bttn.isActive:active span,
.std-portrait-header--right-bttn.isActive:active span,
.std-portrait-header--left-bttn.isActive:active svg,
.std-portrait-header--right-bttn.isActive:active svg {
  color: gray;
}

@media only screen and (orientation: landscape) {
  .std-portrait-header {
    display: none;
  }
}
