body {
  /*font-family: 'open sans', 'HelveticaNeue', 'Helvetica Neue', 'Helvetica-Neue',
    Helvetica, Arial, sans-serif;*/
  font-family: 'FunctionPro_Bookregular', sans-serif;
  font-size: 16px;
  box-sizing: border-box;
  overflow: hidden;
  color: #666;
  background-color: #f2f2f2;

  /* Console shows warnings, because of final spec. But do all browsers support the final spec? */

  /* display: box;
  display: -webkit-box; */
  display: -moz-flex;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;

  /* Fixes issues on mobile devices, where the address bar consumes some pixels. */
  min-height: 100%;

  /*font-weight: bold;*/
  line-height: 1.2;
}

/* Fixes issues on mobile devices, where the address bar consumes some pixels. */
html,
body,
#root {
  height: 100%;
}

/* Disable text selection */
div,
span,
p,
h1,
h2,
h3,
h4,
h5,
h6,
button {
  -moz-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  user-select: none;
}

/*
  React icons don't have classes by default.
  But developers would have to add them manually,
  if Verge3D is in using an 'on-event-of' puzzle piece or some annotations.
  Otherwise an error would frequently appear when React elements are clicked.
  This puzzle piece is looking for classnames and would fail, if
  no class attribute was found on an interactive HTML tag.
  Even if the onClick handler was added to some parent DIV.
  By making SVGs not clickable from the beginning, some
  error occurences could be avoided.
  Please note, that SVGs in general must allow pointer events. Otherweise the
  puzzles editor will not be very interactive anymore.
*/
#root svg {
  pointer-events: none;
}

#root,
.app-body {
  overflow: hidden;
}

.app-body {
  display: inline-flex;
  align-content: space-between;

  /* Support sidebar to overlap/stay right next to canvas */
  position: relative;
  background-color: #fff;
}

#canvas-wrapper {
  width: 100%;
  height: 100%;

  /* 'absolute' when sidebar should overlay, otherwise 'relative'. Parent must always be relative. */
  position: absolute;
}

/* width */
::-webkit-scrollbar {
  width: 0.5rem;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
  background-color: grey;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #364662;
}

@media only screen and (orientation: landscape) {
  .app-body {
    width: 100%;
    height: 100%;
    display: flex;
  }
}

@media only screen and (orientation: portrait) {
  .app-body {
    order: 0;
    -webkit-box-flex: 1 1 auto;
    -moz-box-flex: 1 1 auto;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    height: 100%;
    display: block;
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (orientation: landscape) {
  #canvas-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
  }
}

@media only screen and (pointer: coarse) and (hover: none) and (orientation: landscape) {
  #canvas-wrapper {
    width: 100%;
    height: calc(100% - 6.4rem);
    position: relative;
  }

  #canvas-wrapper.std-playbar--closed {
    height: 100%;
  }
}

@media only screen and (orientation: portrait) {
  #canvas-wrapper {
    width: 100%;
    height: calc(55% - 6.4rem);
    position: unset;
    order: 1;
    transition: height 1s;
    transition-timing-function: ease-in-out;
    display: flex;
    flex-direction: column;
  }

  #canvas-wrapper.isClosed {
    height: 0;
    display: block;
  }

  #canvas-wrapper.isFullscreen {
    height: calc(100% - 6.4rem);
  }
}

.data-sheet {
  font-size: 68%;
}

.data-sheet pre {
  margin: 0.4rem 0 0 0;
}
