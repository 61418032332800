.select-screen {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  font-weight: 700;
}

.select-screen.isClosed {
  display: none;
}

.curtain {
  display: flex;
  background-color: #898787a1;
  width: 100%;
  height: 100%;
}

.select-screen--background {
  background-color: #fff;
  margin: 5rem;
  max-width: 64rem;
  height: min-content;
}

.select-screen--header {
  height: 3rem;
  background: #3f4a4c;
}

.select-screen--content {
  margin: 2rem 2rem 1rem;
}

.select-screen--content-centered-text {
  text-align: center;
}

.select-screen--sideBySide {
  display: flex;
  justify-content: space-around;
  padding: 1rem;
}

.select-screen .border {
  min-width: 0.25rem;
  min-height: 0.25rem;
  border-radius: 1rem;
  opacity: 0.5;
  background-color: #3f4a4c;
  margin: 1rem;
}

.select-screen .select-item {
  width: 40%;
}

.select-screen .select-item:hover {
  border: 1px solid #e4003a;
}

.select-screen .select-item:active {
  border: 1px solid #666;
}

.select-screen .select-item--head-1,
.select-screen .select-item--head-2 {
  text-align: center;
  font-size: 1rem;
  color: #e4003a;
}

.select-screen .select-item--head-1 {
  margin: 1rem 1rem 0 1rem;
}

.select-screen .select-item--head-2 {
  margin: 0rem 1rem 0 1rem;
}

.select-screen .select-item--container {
  display: flex;
  margin: 1rem 0 2rem 0;
  margin: 1rem 0;
  position: relative;
}

.select-screen .select-item--container img {
  width: 90%;
  margin: auto;
}

.select-screen--background {
  overflow: hidden;
  max-height: 90%;
  height: 690px;
  width: 90%;
  margin: auto;
}

.select-screen--sideBySide {
  overflow: auto;
  max-height: 60%;
}

@media only screen and (orientation: portrait) {
  .select-screen--background {
    height: inherit;
  }

  .select-screen--sideBySide {
    display: block;
    max-height: 56%;
  }

  .select-screen .select-item {
    width: 100%;
    height: fit-content;
  }

  .select-item--container {
    display: block;
  }
}

@media only screen and (orientation: landscape) {
  .select-screen .select-item--container img {
    width: -webkit-fill-available;
  }

  .select-screen .select-item {
    display: flex;
    flex-direction: column;
    height: fit-content;
  }

  .select-screen--sideBySide .extra-container {
    display: flex;
    width: 100%;
    margin: auto;
    justify-content: space-around;
  }
}

@media only screen and (orientation: portrait) and (min-height: 1080px) {
  .select-screen--sideBySide {
    max-height: unset;
  }
}

@media only screen and (orientation: landscape) and (min-height: 1080px) {
  .select-screen--sideBySide {
    max-height: 70%;
  }
}
