/* Fixes issues on mobile devices, where the address bar consumes some pixels. */
.app-wrapper {
  height: 100%;
  overflow: hidden;
}

@media only screen and (orientation: portrait) {
  .app-wrapper {
    display: flex;
    flex-direction: column;
    margin: 0;
  }
}

.app-wrapper.isHidden div,
.app-wrapper.isHidden #canvas-wrapper * {
  display: none;
}

.app-wrapper.isHidden .fullscreen-button {
  display: none !important;
}

.app-wrapper #canvas-wrapper {
  display: block !important;
}

.app-wrapper .app-body {
  display: flex !important;
}
