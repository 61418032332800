.std-help--group {
  display: flex;
  flex-direction: column;
  height: 100%;
}

@media only screen and (orientation: portrait) {
  .std-help--group-flex {
    display: flex;
    flex-wrap: wrap;
  }
}

.std-help--group-flex-stretch {
  flex: 0 0 50%;
  margin: auto;
}

.std-help--sideBySide {
  display: inline-flex;
  align-content: space-between;
  /*justify-content: center;*/
  width: 100%;
  margin: 0;
}

.std-help--sideBySide-left,
.std-help--sideBySide-right {
  /*width: 100%;*/
  margin: 1rem 0.5rem;
  display: flex;
}

.std-help--sideBySide-right div {
  margin: auto;
}

.std-help--sideBySide-left div {
  margin: auto 0;
}

@media only screen and (orientation: portrait) {
  .std-help--sideBySide,
  .std-help--sideBySide--left,
  .std-help--sideBySide--right {
    margin: auto;
    width: 100%;
  }
}

.std-help svg {
  width: 2rem;
  fill: white;
  margin: 0 auto;
}

.std-help--icon {
  display: flex;
  align-items: center;
  max-width: 4rem;
  margin: auto;
}

.std-help--icon-larger svg {
  height: 4rem;
  margin: auto;
  display: block;
}

.std-help--icon-text {
  display: flex;
}

.std-help--icon-text span {
  margin: auto;
  font-size: x-large;
}
