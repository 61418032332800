:root {
  /* Copied from https://www.beinlich-pumps.com/de/ */
  --e-global-color-primary: #fff;
  --e-global-color-secondary: #ecebeb;
  --e-global-color-text: #3f4a4c; /* auch als Background Color, weißer Text */
  --e-global-color-accent: #e4003a; /*aut als Background Color , weißer text*/

  --e-global-color-521f10d: #354561;
  --e-global-color-dccadf8: #ffa600;
  --e-global-color-a92075e: #2caeff;
  --e-global-color-436a1c6: #56c1ff;
  --e-global-color-1b18f4e: #ffffff2b;
  --e-global-color-2f3dd9d: #000;

  --hotspot-bg-normal: #3f4a4c;
  --hotspot-bg-hover: #e4003a;
  --hotspot-bg-active: #e4003a;

  /* True set of variables which should be used */
  --main-text-color: #3f4a4c;
  --main-text-color-shadow: lightgray;
  --main-text-color-inverse: #fff;
  --main-text-hover-color: #e4003a;
  --main-text-inactive-color: gray;
  --main-bg: #fff;
  --main-icon-color: #fff;
  --main-icon-color-inverse: #e4003a;

  --less-imporant-color: gray;
  --border-color: gray;

  --secondary-bg: #3f4a4c;

  --copyright-color: darkgray;
  --copyright-border: lightgrey;

  --highlight-color: #e4003a;
  --highlight-active-color: #e4003a;
  --highlight-hover-color: #e4003a;

  --slider-bg-0: #fff;
  --slider-bg-1: #e4003a;
  --slider-bg-2: lightgray;

  --scrollbar-bg: gray;
  --scrollbar-handle: #3f4a4c;
  --scrollbar-hover: #fff;
}

.std-bookmark.isInactive {
  color: var(--main-text-inactive-color);
}

.std-bookmark.isActive {
  color: var(--highlight-color);
}

.std-bookmark:hover {
  color: var(--highlight-hover-color);
}

.std-bookmark:active {
  color: var(--less-imporant-color);
}

.std-bttn-close svg {
  color: #444;
}

.std-bttn-close svg:hover {
  color: var(--highlight-hover-color);
}

.std-bttn-close svg:active {
  color: var(--highlight-active-color);
}

.std-content-page .std-copyright a {
  border-top: 1px solid var(--copyright-border);
}

.std-content-page .std-copyright a:link,
.std-content-page .std-copyright a:visited {
  color: var(--copyright-color);
}

.std-content-page .std-copyright a:hover {
  color: var(--main-text-hover-color);
}

.std-content-page .std-copyright a:active {
  color: var(--copyright-color);
}

.std-floating-window {
  color: var(--main-text-color);
}

.std-playbar--dropdown-menu {
  background-color: var(--secondary-bg);
}

.std-playbar--progress-bar {
  background-color: white;
}

.std-playbar--progress-bar-value {
  background-color: var(--highlight-color);
}

.std-playbar--actions {
  background-color: var(--secondary-bg);
  color: var(--main-icon-color);
}

.std-playbar--actions-bttn-icon:hover svg {
  color: var(--highlight-hover-color);
}

.std-playbar--actions-bttn-icon:active svg {
  color: var(--main-icon-color);
}

.std-playbar--actions-bttn-icon.isActive svg {
  color: var(--main-icon-color);
}

.std-playbar--actions-bttn-icon.isInactive svg {
  color: var(--main-text-inactive-color);
}

.std-playbar--actions-bttn-icon.isActive:hover svg {
  color: var(--highlight-hover-color);
}

.std-playbar--actions-bttn-icon.isInactive:hover svg {
  color: var(--main-text-inactive-color);
}

.std-playbar--progress-bar-step {
  background: #fff;
}

@media only screen and (orientation: portrait) {
  .std-playbar--actions-bttn:last-of-type {
    border-left: 2px solid white;
  }
}

.std-portrait-header {
  background-color: var(--secondary-bg);
  color: white;
}

.std-portrait-header--left-bttn.isActive span,
.std-portrait-header--right-bttn.isActive span,
.std-portrait-header--left-bttn.isActive svg,
.std-portrait-header--right-bttn.isActive svg {
  color: var(--highlight-color);
}

.std-portrait-header--left-bttn:hover span,
.std-portrait-header--right-bttn:hover span,
.std-portrait-header--left-bttn:hover svg,
.std-portrait-header--right-bttn:hover svg {
  color: var(--highlight-hover-color);
}

.std-portrait-header--left-bttn:active span,
.std-portrait-header--right-bttn:active span,
.std-portrait-header--left-bttn:active svg,
.std-portrait-header--right-bttn:active svg {
  color: var(--less-imporant-color);
}

.std-portrait-header--left-bttn.isActive:hover span,
.std-portrait-header--right-bttn.isActive:hover span,
.std-portrait-header--left-bttn.isActive:hover svg,
.std-portrait-header--right-bttn.isActive:hover svg {
  color: var(--highlight-hover-color);
}

.std-portrait-header--left-bttn.isActive:active span,
.std-portrait-header--right-bttn.isActive:active span,
.std-portrait-header--left-bttn.isActive:active svg,
.std-portrait-header--right-bttn.isActive:active svg {
  color: var(--less-imporant-color);
}

.std-sidebar--actions-separator {
  background-color: white;
}

.std-sidebar--actions-bttn-icon {
  color: var(--main-icon-color);
  transition: background-color 0.3s ease-in-out;
}

.std-sidebar--actions-bttn-icon:hover {
  background-color: var(--e-global-color-secondary);
}

.std-sidebar--actions-bttn-icon.isActive {
  color: var(--highlight-color);
}

.std-sidebar--actions-bttn-icon:hover {
  color: var(--highlight-hover-color);
}

.std-sidebar--actions-bttn-icon:active {
  color: var(--less-imporant-color);
}

.std-sidebar--actions-bttn-icon.isInactive {
  color: var(--main-text-inactive-color);
}

.std-sidebar--actions-bttn-icon.isActive:hover {
  color: var(--highlight-hover-color);
}

.std-sidebar--actions-bttn-icon.isActive:active {
  color: var(--less-imporant-color);
}

.std-sidebar--content {
  background-color: var(--main-bg);
  color: var(--main-text-color);
}

@media only screen and (orientation: landscape) {
  .std-sidebar {
    border-right: 2px solid var(--border-color);
  }

  .std-sidebar--default-actions {
    background-color: var(--secondary-bg);
  }
}

@media only screen and (orientation: portrait) {
  .std-sidebar--actions {
    background-color: var(--secondary-bg);
    border-left: 2px solid var(--border-color);
    border-bottom: 2px solid var(--border-color);
  }

  .std-sidebar--actions.isClosed {
    border: 0;
  }

  .std-sidebar {
    border-top: 1px solid white;
  }
}

.std-sidebar--top {
  background-color: var(--secondary-bg);
}

#splash-screen {
  background: var(--main-bg);
}

#splash-screen--progress-bar {
  background-color: var(--secondary-bg);
}

#splash-screen--progress-bar-value {
  background-color: var(--highlight-color);
}

.std-toc {
  background-color: var(--main-bg);
  border-bottom: 1px solid white;
  color: var(--main-text-color);
}

.std-toc.isClosed {
  border-bottom: 0 solid white;
}

.std-toc--head {
  color: var(--main-text-color-inverse);
  background-color: var(--secondary-bg);
  border-bottom: 1px solid white;
}

.std-toc--content-item:hover {
  color: var(--highlight-hover-color);
}

.std-toc--content-item:active {
  color: var(--less-imporant-color);
}

.std-toc--content-item.isActive {
  color: var(--highlight-color);
}

.std-toc--content-item.isActive:hover {
  color: var(--highlight-hover-color);
}

.std-toc--content-item.isActive:active {
  color: var(--less-imporant-color);
}

.std-video-dialog .std-video-dialog--container {
  background-color: var(--main-bg);
}

.std-video-dialog--close-bttn svg {
  color: var(--less-imporant-color);
}

.std-video-dialog--close-bttn:hover svg {
  color: var(--highlight-color);
}

.std-video-dialog--close-bttn:active svg {
  color: var(--highlight-hover-color-color);
}

.user-info--icon svg {
  fill: var(--highlight-color);
}

/*.zoom-widget {
    border: 1px solid #666;
    background-color: white;
  }
  
  .zoom-widget hr {
    background-color: #dbdcdd;
  }
  
  .zoom-widget--bttn:hover {
    background-color: #f2f2f2;
  }
  
  .zoom-widget--bttn:active {
    background-color: #fff;
  }
  
  .zoom-widget--bttn.disabled {
    background-color: #dbdcdd;
  }*/

.std-side-menu {
  background-color: var(--main-bg);
  color: var(--main-text-color);
}

@media only screen and (pointer: fine) and (hover: hover) and (orientation: landscape) {
  #menu-info {
    color: var(--main-text-color);
    text-shadow: 1px 1px var(--main-text-color-shadow);
  }

  .std-content-page {
    background: #ffffff99;
  }
}

.std-content-page .dev-info {
  border-top: 1px solid var(--highlight-color);
  color: var(--highlight-color);
}

.std-content-page .dev-info--markdown {
  color: var(--e-global-color-accent);
}

.std-content-page .dev-info--html {
  color: var(--e-global-color-436a1c6);
}

.std-content-page #dev-sample--id {
  color: yellow;
}

.std-content-page .dev-sample--class {
  color: yellowgreen;
}

.std-content-page--error {
  color: red;
}

.std-content-page table td,
.std-content-page table th {
  border: 1px solid #ddd;
}

.std-content-page table tr:nth-child(even) {
  background-color: lightgray;
}

.std-content-page .md-button {
  background-color: var(--secondary-bg);
  color: var(--main-text-color-inverse);
  text-shadow: none;
}

.std-content-page .md-button:hover {
  background-color: var(--e-global-color-a92075e);
}

.std-content-page .md-button:active {
  background-color: #fff;
  color: var(--main-text-color);
}

.std-content-page a:link,
.std-content-page a:visited {
  color: var(--main-text-hover-color);
}

.std-content-page a:hover {
  color: var(--main-text-color);
}

.std-content-page a:active {
  color: var(--main-text-hover-color);
}

/* unvisited and visited */
.std-content-page .md-link a:link,
.std-content-page .md-link a:visited {
  color: var(--main-text-color);
}

.std-content-page .md-link a:hover {
  color: var(--highlight-color);
}

.std-content-page .md-link a:active {
  color: var(--highlight-active-color);
}

.std-help svg {
  fill: var(--main-text-color);
}

.std-lang--bttn {
  background-color: var(--main-bg);
  color: var(--main-text-color);
}

.std-lang--bttn:hover {
  background-color: var(--secondary-bg);
  color: var(--main-text-color-inverse);
}

.std-settings--group-thumb {
  background-color: white;
  color: black;
  border: 1px solid darkgray;
}

.std-settings--group-track {
  background-color: var(--slider-bg-0);
}

.std-settings--group-track-0 {
  background-color: var(--slider-bg-1);
}

.std-settings--group-track-1 {
  background-color: var(--slider-bg-2);
}

body {
  color: #666;
  background-color: #f2f2f2;
}

.app-body {
  background-color: #fff;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px var(--scrollbar-bg);
  background-color: var(--scrollbar-bg);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--scrollbar-handle);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--scrollbar-hover);
}

.v3d-annotation.std-hotspot {
  background-color: var(--hotspot-bg-normal);
  color: var(--main-text-color-inverse);
}

.v3d-annotation.std-hotspot:hover {
  background-color: var(--hotspot-bg-hover);
  color: var(--main-text-color-inverse);
}

.v3d-annotation.std-hotspot:active {
  background-color: var(--hotspot-bg-active);
  color: var(--main-text-color-inverse);
}

.logo-container.highlight {
  border: 0.2rem solid var(--main-text-hover-color);
}

#bttn-forward--playbar:hover:active svg,
#bttn-backward--playbar:hover:active svg {
  fill: var(--main-icon-color-inverse);
}
