.std-floating-window {
  position: absolute;
  color: #fff;
  z-index: 1;
  width: 20rem;
  left: 2rem;
  top: 2rem;
  pointer-events: none;
  display: none;
}

#std-floating-window--2 {
  left: unset;
  right: 2rem;
}

#std-floating-window--3 {
  top: unset;
  bottom: 2rem;
}

#std-floating-window--4 {
  left: unset;
  top: unset;
  right: 2rem;
  bottom: 2rem;
}

#std-floating-window--5 {
  left: unset;
  top: unset;
  right: 2rem;
  bottom: 2rem;
}

#std-floating-window--6 {
  left: unset;
  top: unset;
  right: 2rem;
  bottom: 2rem;
}

#std-floating-window--7 {
  left: unset;
  top: unset;
  right: 2rem;
  bottom: 2rem;
  background: #aaaa;
  text-shadow: 1px 1px black;
}
