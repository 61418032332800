.std-lang--bttn {
  width: 100%;
  height: 4rem;
  display: flex;
  margin: 0.4rem 0;
  background-color: #7987a0;
  color: white;
  font: larger;
}

.std-lang--bttn div {
  margin: auto 2rem;
}

.std-lang--bttn:hover {
  cursor: pointer;
}
