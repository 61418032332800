.logo-container-2d {
  position: absolute;
  top: 1rem;
  width: 100%;
  margin: auto;
  z-index: 1;
  pointer-events: none;
}

.logo-container-2d > div {
  position: relative;
  margin: auto;
  width: 15rem;
}

@media only screen and (orientation: portrait) {
  .logo-container-2d {
    top: 47%;
  }

  .logo-container-2d > div {
    width: 10rem;
  }
}

.logo-container-2d > div > div {
  position: absolute;
  top: 0;
  width: inherit;
}

.logo-2d {
  margin: auto;
}

#eholding-intro-logo {
  width: 100%;
}

#hbe-intro-logo-1,
#hbe-intro-logo-2 {
  width: 100%;
}

#dst-intro-logo {
  width: 80%;
}

#beinlich-intro-logo {
  width: 100%;
}

#vse-intro-logo {
  width: 70%;
}

#oleotec-intro-logo {
  width: 80%;
}
