.std-playbar {
  z-index: 2;
}

.std-playbar--dropdown-menu {
  position: relative;
  background-color: #364662;
  color: #fff;
  height: 3rem;
}

.std-playbar--progress-bar {
  height: 0.4rem;
  background-color: white;
}

.std-playbar--progress-bar-value {
  height: inherit;
  width: 40%;
  background-color: orange;
}

.std-playbar--actions {
  height: 3rem;
  display: inherit;
  display: flex;
  align-content: space-between;
  justify-content: center;
  background-color: #364662;
  color: #fff;
}

.std-playbar--actions-bttn-icon {
  max-width: 4rem;
  width: unset;
  margin: auto;

  /* To center his child, the position of its parent must be relative */
  display: flex;
  align-items: center;
  height: 100%;
}

.std-playbar--actions-bttn-icon svg {
  /* Required for centering */
  margin: 0 auto;
  display: block;
  font-size: 1.75rem;
}

.std-playbar--actions-bttn {
  margin: auto;
  width: 100%;
  height: 100%;
}

.std-playbar--actions-bttn.isHidden {
  display: none;
}

.std-playbar--actions-bttn-icon:hover svg {
  color: darkorange;
}

.std-playbar--actions-bttn-icon:active svg {
  color: gray;
}

.std-playbar--actions-bttn-icon.isActive svg {
  color: orange;
}

.std-playbar--actions-bttn-icon.isInactive svg {
  color: gray;
}

.std-playbar--actions-bttn-icon.isActive:hover svg {
  color: darkorange;
}

.std-playbar--actions-bttn-icon.isInactive {
  pointer-events: none;
}

.std-playbar--actions-bttn-icon.isInactive:hover svg {
  color: gray;
}

.std-playbar--dropdown-menu.isOpen {
  border-radius: 0;
}

.std-playbar--dropdown-menu:hover {
  cursor: pointer;
}

.std-playbar--progress-bar-step {
  position: absolute;
  height: 0.4rem;
  width: 0.25rem;
  background: #657186;
  left: 50%;
}

@media only screen and (orientation: landscape) {
  .std-playbar {
    position: absolute;
    bottom: 1rem;
    left: 0;
    right: 0;
    width: 20rem;
    margin: auto;
    max-width: 90%;
  }

  .std-playbar--dropdown-menu {
    border-radius: 0.5rem 0.5rem 0 0;
  }

  .std-playbar.hiddenInLandscapeMode {
    display: none;
  }

  .std-playbar--actions-bttn.hiddenInLandscapeMode {
    display: none;
  }
}

@media only screen and (orientation: portrait) {
  .std-playbar {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  .std-playbar--actions {
    border-radius: 0;
  }

  .std-playbar--dropdown-menu {
    border-radius: 0;
  }

  .std-playbar--actions-bttn:last-of-type {
    border-left: 2px solid white;
  }
}

@media only screen and (pointer: coarse) and (hover: none) and (orientation: landscape) {
  .std-playbar {
    width: 100%;
    max-width: unset;
    position: relative;
    bottom: unset;
  }

  .std-playbar--dropdown-menu,
  .std-playbar--actions {
    border-radius: 0;
  }
}
