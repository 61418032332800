.std-settings--group {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.std-settings--group-dropdown {
  margin: 0 1rem;
}

.std-settings--group-slider {
  margin: 2rem 1rem 3rem 1rem;
  height: 2rem;
}

@media only screen and (orientation: landscape) {
  /* Set static width in landscape mode, to avoid error, triggered by open-animation of settings menu */
  .std-settings--group-slider {
    width: 14rem;
  }
}

.std-settings--group-thumb {
  height: 1.5rem;
  line-height: 25px;
  width: 25px;
  text-align: center;
  background-color: white;
  color: black;
  border-radius: 50%;
  cursor: grab;
  font-size: small;
  border: 1px solid darkgray;
  margin: 0;
  z-index: unset !important;
}

.std-settings--group-track {
  background-color: white;
  height: 0.5rem;
  border-radius: 2rem;
  top: 0.5rem;
  margin: 0;
}

.std-settings--group-track-0 {
  background-color: white;
}

.std-settings--group-track-1 {
  background-color: lightgray;
}
